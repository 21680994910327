<template>
	<div>
		<s-crud
			title="Temperatura y horas etileno"
            height="auto"
			:config="config"
			:filter="filter"
			add
			@save="save($event)"
			edit
			remove
			search-input
		>
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col cols="3" lg="3" md="6" sm="12">							
							<s-select
								:items="itemsStateTunnel"
								item-value="MtsID"
								item-text="MtsDescription"
								label="Estado"
								v-model="props.item.TypeStateFruit"
							>
							</s-select>
						</v-col>
						
						<v-col cols="3" lg="3" md="6" sm="12">
							<s-select-definition
								label="Tipo Cultivo"
								v-model="props.item.TypeCultive"
								:def="1173"
							></s-select-definition>
						</v-col>

						<v-col
							cols="6"
							md="6"
							lg="3"
							>
							<s-select-variety
								:cultiveID="props.item.TypeCultive"
								label="Variedad"
								full
								:text="props.item.VrtName"
								:value="VrtID"
								v-model="props.item.VrtID"
								
								/>
							</v-col>
					
						<v-col cols="3" lg="3" md="6" sm="12">
							<s-text
								label="Temperatura"
								v-model="props.item.Temperature"
								ref="txtTemperature"
							></s-text>
						</v-col>
						<v-col cols="3" lg="3" md="6" sm="12">
							<s-text
								label="Temperatura Maxima"
								v-model="props.item.MaxTemperature"
								ref="txtMaxTemperature"
							></s-text>
						</v-col>
						<v-col cols="3" lg="3" md="6" sm="12">
							<s-text
								
								label="Temperatura Minima"
								v-model="props.item.MinTemperature"
								ref="txtMinTemperature">

							</s-text>
						</v-col>

                        <v-col cols="3" lg="3" md="6" sm="12">
							<s-text								
								label="Horas Etileno"
								v-model="props.item.HoursEthylene"
								ref="txtHoursEthylene">
							</s-text>
						</v-col>

                        <v-col cols="3" lg="3" md="6" sm="12">
							<s-text								
								label="Horas Etileno Maxima"
								v-model="props.item.MaxHoursEthylene"
								ref="txtMaxHoursEthylene">
							</s-text>
						</v-col>

                        <v-col cols="3" lg="3" md="6" sm="12">
							<s-text								
								label="Horas Etileno Minimo"
								v-model="props.item.MinHoursEthylene"
								ref="txtMinHoursEthylene">
							</s-text>
						</v-col>
					</v-row>
				</v-container>
			</template>

			
		</s-crud>
	</div>
</template>

<script>
import  _sTemperatureHoursEthyleneService  from '@/services/FrozenProduction/TemperatureHoursEthyleneService.js'
import _sMaturationTunnelPallet from "../../../services/FrozenProduction/MaturationTunnelPalletService";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";

export default {
	components: {SSelectVariety},
    data(){
        return{
			TypeCultive: 0,
			VrtID: 0,
			itemsStateTunnel: [],
            config: {
                model:{
					TehID: "ID",
                },
                service: _sTemperatureHoursEthyleneService,
                headers: [
						{text: "ID", value: "TehID"},
						{text: "Estado", value: "TypeStateFruitName"},
						{text: "Tipo Cultivo", value: "TypeCultiveName"},
						{text: "Variedad", value: "VrtName"},						
						{text: "Temperatura", value: "Temperature"},
						{text: "Temp. Maximo", value: "MaxTemperature"},
						{text: "Temp. Minimo", value: "MinTemperature"},
                        {text: "Horas etileno", value: "HoursEthylene"},
						{text: "H.E. Maximo", value: "MaxHoursEthylene"},
						{text: "H.E. Minimo", value: "MinHoursEthylene"},
						
					]
            },
            filter: { }
        }
    },
	created() {
			this.initialize()
		},
    methods: {
		save(item) {
			if(item.StateName == "")
			{
				this.$fun.alert("Ingrese el estado", "warning");
				this.$refs.focus("SSelectTypeStateName");
				return;
			}
			if(item.TypeCultive == "")
			{
				this.$fun.alert("Ingrese el tipo de cultivo", "warning");
				this.$refs.focus("item.TypeCultive");
				return;
			}
			item.save();
		},

		initialize() {
			_sMaturationTunnelPallet.listStateMaturation(this.$fun.getUserID()).then(r => {
					if(r.status == 200){
						this.itemsStateTunnel = r.data
						this.itemsStateTunnel.splice(3,1);
					}
				})
		}
	},
}


</script>
